class Select {
		constructor(select) {
				this.select = select;
				this.name = this.select.getAttribute('data-custom-select');
				this.btn = this.select.querySelector('.c-custom-select-btn');
				this.container = this.select.querySelector('.c-custom-select-container');

				this.btnFn = (e) => {
						this.select.classList.toggle('c-custom-select--active');
				};
		}

		init() {
				this.btn.addEventListener('click', )
		}

		toggle() {

		}
}

export default Select;