export default function ScrollBtn() {
	$(() => {
		$(window).on('scroll', () => {
			if ($(window).scrollTop() <= 250) { $('#goTop').fadeOut(600); } else $('#goTop').fadeIn(600);
			if ($(window).scrollTop() >= $(document).height() - 999) { $('#goBottom').fadeOut(600); } else { $('#goBottom').fadeIn(600); }
		});
		$(window).on('scroll.banner',() =>{
			if ($(window).scrollTop() <= 250 && (localStorage.getItem('hideBanner'))) {
				$('#jsMessageBottomNotAuth').fadeOut(300);
			} else {
				$('#jsMessageBottomNotAuth').fadeIn(600);
				$(window).off('scroll.banner');
			}
		});
		$('#jsHidePopupBottomRegister').on('click', (e) => {
			e.preventDefault();
			if(window.localStorage){
				localStorage.setItem('hideBanner', true);
			}
			$('#jsMessageBottomNotAuth').fadeOut(300);
		});
		$('#goTop').on('click', (e) => {
			e.preventDefault();
			$('html, body').animate({ scrollTop: 0 }, 'slow');
		});
		$('#goBottom').on('click', (e) => {
			e.preventDefault();
			$('html, body').animate({ scrollTop: $(document).height() }, 'slow');
		});
	});
}
