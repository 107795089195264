import 'core-js/stable';
import '@IncludesScss/commonCssBundle';

import Header, { HeaderOld } from '@Js/blocks/header/Header';
import ButtonScroll from '@Js/blocks/button-scroll/ButtonScroll';
import axios from 'axios';
import { getSelector } from '@Js/helpers';
import Modal from '@JsComponents/Modal/Modal';
import tippy from 'tippy.js';
import styles from '@StylesJson/common.module';
import _debounce from 'lodash/debounce';
import cssVars from 'css-vars-ponyfill';
import 'scroll-restoration-polyfill';
import { noty_s_center, noty_s_center_error } from '@JsOld/scripts/lib/vendor';
import checkMedia from '@Js/scripts/Media';
import lozad from 'lozad';
import '@Js/blocks/news';
import 'simplebar/dist/simplebar.min.css';
import _isNull from 'lodash/isNull';
import qs from 'qs';

const observerPicture = lozad('.lozad-picture', {
  threshold: 0.25,
  load(el) {
    if (el.nodeName.toLowerCase() === 'picture') {
      const imgNested = el.querySelector('img');
      const sourceElements = el.querySelectorAll('source');
      sourceElements.forEach((source) => {
        if (source.getAttribute('data-srcset')) {
          source.setAttribute('srcset', source.getAttribute('data-srcset'));
          source.removeAttribute('data-srcset');
        }
      });
      imgNested.setAttribute('src', imgNested.getAttribute('data-src'));
      imgNested.removeAttribute('data-src');
    }
  },
});
observerPicture.observe();

const observerImage = lozad('.lozad-image', {
  rootMargin: '16px',
  threshold: 0.25,
});
observerImage.observe();

require('mutationobserver-shim');

cssVars({
  shadowDOM: true,
});

tippy('[data-tippy-tooltip]', {
  theme: 'material',
  appendTo: 'parent',
  interactive: true,
});

let mobileTippy;

if (checkMedia('tablet')) {
  tippy('[data-tippy-tooltip-mobile]', {
    theme: 'material',
    appendTo: 'parent',
    hideOnClick: true,
  });
}

window.addEventListener('resize', _debounce(() => {
  if (checkMedia('tablet') && !mobileTippy) {
    tippy('[data-tippy-content-mobile]', {
      theme: 'material',
    });
  } else if (mobileTippy) {
    mobileTippy.disable();
  }
}, 100));

if (window.IS_REACT_SEARCH) {
  new Header();
} else {
  new HeaderOld();
}

new ButtonScroll();

document.addEventListener('click', (e) => {
  if (e.target.closest('[data-module-action]')) {
    e.stopPropagation();
    const action = e.target.closest('[data-module-action]');
    const actionName = action.getAttribute('data-module-action');
    const parent = action.parentNode;

    switch (actionName) {
      case 'password-show': {
        const input = parent.querySelector('input');
        const inputType = input.getAttribute('type');
        inputType === 'password' ? input.setAttribute('type', 'text') : input.setAttribute('type', 'password');
        input.focus();
        break;
      }
      case 'copy-value': {
        const input = parent.querySelector('input');
        input.focus();
        input.select();
        try {
          document.execCommand('copy');
          input.blur();
          if (window.getSelection) {
            window.getSelection()
              .removeAllRanges();
          } else if (document.selection) {
            document.selection.empty();
          }
          action.classList.add('copy');
          tippy(action, {
            content: 'Ссылка скопирована!',
            showOnCreate: true,
          });
        } catch (err) {
          throw new Error('К сожалению, мы не смогли скопировать ссылку на лот!');
        }
        break;
      }
      case 'show-more': {
        if (parent.classList.contains('show')) {
          parent.classList.remove('show');
        } else {
          parent.classList.add('show');
        }
        break;
      }
      case 'show-more-mobile': {
        if (checkMedia('tablet')) {
          if (parent.classList.contains('show')) {
            parent.classList.remove('show');
          } else {
            parent.classList.add('show');
          }
        }
        break;
      }
      default: {
        return null;
      }
    }
  }
});

document.querySelectorAll('[data-module="modal"]')
  .forEach((modal) => {
    new Modal(modal);
  });

function staticClickFn() {
  this.staticBlock.classList.remove(styles.staticClick);
  document.body.removeEventListener('click', this);
}

document.querySelectorAll('[data-static-click="true"]')
  .forEach((block) => {
    block.addEventListener('click', () => {
      block.classList.add(styles.staticClick);

      document.body.addEventListener('click', {
        handleEvent: staticClickFn,
        staticBlock: block,
      }, true);
    });
  });

document.querySelectorAll(getSelector(styles.iconTransition))
  .forEach((block) => {
    const button = block.closest('button');
    button.addEventListener('click', () => {
      block.querySelectorAll('svg')
        .forEach((icon) => {
          const ariaHidden = icon.getAttribute('aria-hidden');
          if (ariaHidden === null) {
            icon.setAttribute('aria-hidden', true);
          } else {
            icon.removeAttribute('aria-hidden');
          }
        });
    });
  });

const inputSearchTimes = document.querySelectorAll(getSelector(styles.inputSearchControlsTimes));
inputSearchTimes.forEach((times) => {
  const button = times;
  button.addEventListener('click', () => {
    button.closest(`.${styles.inputSearchWrapper}`)
      .querySelector(getSelector(styles.inputSearch)).value = '';
  });
});

if (!checkMedia('tablet')) {
  if (document.querySelector('.ForumListTable-HiddenLinksBtn')) {
    document.querySelector('.ForumListTable-HiddenLinksBtn')
      .addEventListener('click', () => {
        document.querySelector('.ForumListTable-HiddenLinks')
          .classList
          .toggle('ForumListTable-HiddenLinks--show');
      });
  }
}

window.noty_s_extend = noty_s_center;
window.noty_s_error_extend = noty_s_center_error;

const jwhelp = $('#help-btn');
const action = 'click.jwhelpInit';
jwhelp.on(action, (e) => {
  const obj = $(e.currentTarget)
    .off(action)
    .addClass('loading');
  $.getScript('https://www.google.com/recaptcha/api.js?onload=jwHelpLoaded&render=explicit');
  window.jwHelpLoaded = () => {
    $.getScript('/forum/js/100sp/jwhelp.js')
      .done(() => {
        obj.jwhelp(window.jwhelpData);
        obj.click()
          .removeClass('loading');
      });
  };
  return !1;
});

// eslint-disable-next-line no-underscore-dangle
window._isNull = _isNull;

window.uloginAuth = function preview(token) {
  $.getJSON(
    `//ulogin.ru/token.php?host=${encodeURIComponent(
      window.location.toString(),
    )}&token=${token}&callback=?`,
    (result) => {
      const data = $.parseJSON(result.toString());
      if (!data.error) {
        axios
          .post(
            '/forum/ajax.php',
            qs.stringify({
              token,
              task: 'ulogin',
              option: 'optwear\\AjaxRequest',
            }),
          )
          .then(() => {
            window.location.reload();
          });
      }
    },
  );
};
