import _throttle from 'lodash/throttle';
import checkMedia from '@Js/scripts/Media';
import stylesFooter from '@StylesJson/footer.module';
import { getSelector } from '@Js/helpers';
import stylesHeader from '@StylesJson/header.module';

class ButtonScroll {
	constructor() {
		this.buttons = document.querySelector(getSelector(stylesFooter.buttonsScroll));
		this.buttonTop = document.querySelector(
			`${getSelector(stylesFooter.buttonScroll)}[data-scroll-position="top"]`
		);
		this.buttonBottom = document.querySelector(
			`${getSelector(stylesFooter.buttonScroll)}[data-scroll-position="bottom"]`
		);

		this.borderHeight = document.documentElement.clientHeight / 2;

		this.isScrollAnimation = false;

		const self = this;

		this.scrollClickFn = function (e) {
			const button = e.target.closest(getSelector(stylesFooter.buttonScroll));
			const position = button.getAttribute('data-scroll-position');

			if (!self.isScrollAnimation) {
				self.isScrollAnimation = true;
				if (position === 'bottom') {
					$('html, body')
						.stop()
						.animate(
							{
								scrollTop: $(document).height(),
							},
							1000,
							() => {
								self.isScrollAnimation = false;
							}
						);
				} else {
					$('html, body')
						.stop()
						.animate({ scrollTop: 0 }, 1000, () => {
							self.isScrollAnimation = false;
						});
				}
			}
		};

		this.scrollFn = () => {
			if (
				(window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					0) < self.borderHeight
			) {
				self.buttonTop.classList.add(stylesFooter.buttonScrollHidden);
			} else {
				self.buttonTop.classList.remove(stylesFooter.buttonScrollHidden);

				if (
					(window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop ||
						0) +
						window.innerHeight ===
					document.documentElement.scrollHeight
				) {
					self.buttonBottom.classList.add(stylesFooter.buttonScrollHidden);
				} else {
					self.buttonBottom.classList.remove(stylesFooter.buttonScrollHidden);
				}
			}
		};

		if (this.buttonTop && this.buttonBottom) {
			this.init();
		}
	}

	init() {
		this.scrollFn();
		window.addEventListener('scroll', _throttle(this.scrollFn, 15), false);
		this.buttonTop.addEventListener('click', this.scrollClickFn, false);
		this.buttonBottom.addEventListener('click', this.scrollClickFn, false);
	}
}

export default ButtonScroll;
