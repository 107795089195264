const getSelector = (classname) => `.${classname}`;
const siteUrl = (url) => SITE_URL + url;

// в принципе l0 нужно по причине того что бэкэнд
// все ссылки с новым хранилищем отдаст изначально с https://...
const new_image_server_url = 'https://img2.optwear.ru/l0/'

export const graphqlErrorsHandler = (error) => {
  if (error.graphQLErrors) {
    // eslint-disable-next-line no-console
    error.graphQLErrors.map(({ message }) => console.log(
      `[GraphQL error]: Message: ${message}`,
    ));
  }

  // eslint-disable-next-line no-console
  if (error.networkError) console.log(`[Network error]: ${error.networkError}`);
};

export const isSafari = () => {
  const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
  const safariAgent = navigator.userAgent.indexOf('Safari') > -1;

  if ((chromeAgent) && (safariAgent)) {
    return false;
  }
  return safariAgent;
};

// Переходный вариант призванный заменить вот это
// `${PRODUCTION_IMG_URL}/forum/images/lots/${item.itemImages[0].url}`
// ну и с учетом переезда на новый сервер картинок
export const resolveLotImageURL = (url_str) => {
  
  if (!url_str || typeof(url_str) != "string") {
    return PRODUCTION_URL + '/forum/images/icons/image-preloader.svg'
  }

  // Внимание!
  // После обновления бэкэнда API будет отдавать новые картинки ТОЛЬКО с http(s)
  // то есть относительные ссылки == старое хранилище, живем с этим пока
  const protocol_url_part = url_str.substring(0, 4)
  if (protocol_url_part == "http") {
    return url_str;
  } else {
    return new_image_server_url + '800x800/' + url_str;
  }
}

// То же самое только для превьюх
export const resolveLotImageURLThumb = (url_str) => {
  
  if (!url_str || typeof(url_str) != "string") {
    return PRODUCTION_URL + '/forum/images/icons/image-preloader.svg'
  }

  const protocol_url_part = url_str.substring(0, 4)
  if (protocol_url_part == "http") {
    return url_str;
  } else {
    // если отдается с thumb -- реплейсим
    let no_thmb = url_str.replace('thumb/', '');
    return new_image_server_url + '200x200/' + no_thmb;
  }
}

export {
  getSelector,
  siteUrl,
};
