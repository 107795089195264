import '@Js/common';

import 'intersection-observer';
import {Noty, noty_a, noty_e, noty_s, noty_w} from './scripts/lib/vendor';

import CommonForum from './scripts/common/common.forum';
import spoilerHeader from './scripts/common/common.forum.spoiler';
import { ScrollBtn } from './scripts/blocks/SiteFooter/SiteFooter';
import Jumpbox from './scripts/blocks/Jumpbox/Jumpbox';
import TopicPostCollapser from './scripts/blocks/Topic/TopicPostProfile';
import Select from './scripts/common/Select';

const inputSearchReset = document.querySelectorAll('.c-input-search-controls__reset');
inputSearchReset.forEach(reset => {
	reset.addEventListener('click', () => {
		reset.closest('.c-input-search__wrapper').querySelector('.c-input-search').value = '';
	});
});

const hiddenBlockBtn = document.querySelectorAll('.m-hidden-block__btn');
hiddenBlockBtn.forEach(btn => {
	btn.addEventListener('click', (e) => {
		const target = e.target;
		target.closest('.m-hidden-block').classList.add('m-hidden-block--show');
		target.remove();
	});
});

const customSelect = document.querySelectorAll('.c-custom-select');
customSelect.forEach(select => {
	new Select(select);
});

if (!String.prototype.trim) {
	(function () {
		// Вырезаем BOM и неразрывный пробел
		String.prototype.trim = function () {
			return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
		};
	}());
}
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (let i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}
// import 'babel-polyfill';
window.noty_s = noty_s;
window.noty_e = noty_e;
window.noty_a = noty_a;
window.noty_w = noty_w;
window.Noty = Noty;
window.noty = function (params) {
	const obj = new Noty(params);
	obj.show();
	return obj;
};

CommonForum();
spoilerHeader();
// socialWidget();
ScrollBtn();
Jumpbox();
TopicPostCollapser();




/**
 * Равны ли строки, если из них убрать перенос строки
 * @param b
 * @param a
 * @returns {boolean}
 */
function strequ(b, a) {
	if (b == null) {
		b = '';
	}
	if (a == null) {
		a = '';
	}
	return b.replace(/\s+/g, '') === a.replace(/\s+/g, '');
}

window.strequ = strequ;
