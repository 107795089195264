/* eslint no-undef: 'off' */

// import {isMobile} from "../../common/common.sp";

import Search from '@Js/blocks/Search/Search';
import checkMedia from '@Js/scripts/Media';
import { getSelector } from '@Js/helpers';
import styles from '@StylesJson/common.module';
import stylesHeader from '@StylesJson/header.module';
import stylesHeaderMobile from '@StylesJson/header-mobile.module';
import stylesHeaderTop from '@StylesJson/header-top.module';
import stylesHeaderMiddle from '@StylesJson/header-middle.module';
import stylesHeaderBottom from '@StylesJson/header-bottom.module';
import _throttle from 'lodash/throttle';
import { scrollFixed } from '@JsReact/helpers/client';
// import Hammer from 'hammerjs';
// import Draggable from '@shopify/draggable/lib/draggable';


class HeaderOld {
	constructor() {
		this.header = document.querySelector(getSelector(stylesHeader.header));
		this.headerTop = document.querySelector(getSelector(stylesHeaderTop.headerTop));
		this.headerMiddle = document.querySelector(getSelector(stylesHeaderMiddle.headerMiddle));
		this.headerMobile = document.querySelector(getSelector(stylesHeaderMobile.headerMobileContainer));
		this.searchMobile = this.header.querySelector(getSelector(stylesHeaderMiddle.searchContainer));
		this.actionsPanel = this.header.querySelector(getSelector(stylesHeaderMiddle.actions));
		this.headerMenuHelper = this.headerMobile.querySelector(getSelector(stylesHeaderBottom.helper));
		this.headerMenuHelperButton = this.headerMobile.querySelector(getSelector(stylesHeaderBottom.helperButton));
		this.headerMenuHelperTitle = this.headerMobile.querySelector(getSelector(stylesHeaderBottom.helperTitleInner));
		this.headerProfile = this.headerTop.querySelector(getSelector(stylesHeaderTop.profile));
		this.headerProfileOptions = this.headerTop.querySelector(getSelector(stylesHeaderTop.profileOptions));
		this.headerMenuHelperActive = false;
		this.currentScroll = 0;

		const self = this;

		this.openHeaderMobileFn = function (e) {
			e.preventDefault();
			e.stopPropagation();
			const scrollY = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
			if (document.getElementById('headerMiddle').classList.contains('scroll')) {
				document.getElementById('headerMiddle').classList.add('scroll-important');
			}
			document.body.style.position = 'fixed';
			document.body.style.top = `-${scrollY}px`;
			document.documentElement.classList.add('header-mobile-open');
			document.body.addEventListener('click', {
				handleEvent: self.bodyClickFn,
				block: self.headerMobile,
				mobile: true,
				callback: () => self.closeBlockMobileFn('header')
			}, false);
		};

		this.openSearchMobileFn = function (e) {
			e.preventDefault();
			e.stopPropagation();
			const scrollY = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
			if (document.getElementById('headerMiddle').classList.contains('scroll')) {
				document.getElementById('headerMiddle').classList.add('scroll-important');
			}
			document.body.style.position = 'fixed';
			document.body.style.top = `-${scrollY}px`;
			document.documentElement.classList.add('search-mobile-open');
			document.getElementById('searchBoxInput').focus();
			document.body.addEventListener('click', {
				handleEvent: self.bodyClickFn,
				block: self.searchMobile,
				mobile: true,
				callback: () => self.closeBlockMobileFn('search')
			}, false);
		};

		this.openNavigationItemFn = function (e) {
			const target = e.target;
			const item = target.closest(getSelector(stylesHeaderBottom.item));

			if (item) {
				const list = item.querySelector(getSelector(stylesHeaderBottom.list));
				if (list) {
					e.preventDefault();
					list.classList.add('open');
					self.headerMenuHelperFn();
				}
			}
		};

		this.toggleProfileFn = function (e) {

			const isOptions = e.target === self.headerProfileOptions || self.headerProfileOptions.contains(e.target);

			if (!isOptions) {
				e.preventDefault();
				e.stopPropagation();

				if (checkMedia('desktop') && self.headerProfileOptions) {
					if (self.headerProfile.classList.contains('show-options')) {
						self.headerProfile.classList.remove('show-options');
					} else {
						self.headerProfile.classList.add('show-options');
						document.body.addEventListener('click', {
							handleEvent: self.bodyClickFn,
							block: self.headerProfile,
							mobile: false,
							callback: self.toggleProfileFn
						}, false);
					}
				}
			}
		};

		this.bodyClickFn = function (e) {
			const target = e.target,
				block = this.block,
				mobile = this.mobile || false;
			let isBlock;
			isBlock = target === block || block.contains(target);

			if (!isBlock) {
				if (mobile) {
					const scrollY = document.body.style.top;
					document.body.style.position = '';
					document.body.style.top = '';
					window.scrollTo(0, parseInt(scrollY || '0') * -1);
					if (document.getElementById('headerMiddle').classList.contains('scroll-important')) {
						document.getElementById('headerMiddle').classList.remove('scroll-important');
					}
				}
				this.callback(e);
				// self.closeBlockMobileFn(this.block);
				document.body.removeEventListener('click', this);
			}
		};

		this.scrollFn = function () {
			const headerMiddleHeight = self.headerMiddle.offsetHeight;
			if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > headerMiddleHeight) {
				if (!self.headerMiddle.classList.contains('scroll')) {
					self.headerMiddle.classList.add('scroll');
				}
			} else {
				if (self.headerMiddle.classList.contains('scroll')) {
					self.headerMiddle.classList.remove('scroll');
				}
			}
		};

		this.topBtnFn = function () {
			$('html, body')
				.stop()
				.animate({ scrollTop: 0 }, 500);
		}

		this.clickHeaderMenuHelperFn = function () {

			if (self.headerMenuHelperActive) {
				let lists = self.getOpenList();
				const [lastList, ...otherLists] = lists.reverse();

				if (lists.length !== 0) {

					lastList.classList.remove('open');

					if (otherLists.length >= 1) {
						const [prevList] = otherLists;
						self.changeHeaderMenuHelperTitle(self.getNavigationItemTitle(prevList));
					} else {
						self.headerMenuHelperFn();

						self.headerMenuHelperActive = false;
						self.headerMenuHelper.classList.remove('active');
					}
				}
			}
		};

		// this.actionsPanelFn = function () {
		// 	console.log((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0));
		// 	if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > self.currentScroll) {
		// 		self.actionsPanel.classList.add('hide');
		// 	} else if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) < self.currentScroll || (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) === 0) {
		// 		self.actionsPanel.classList.remove('hide');
		// 	}
		// 	self.currentScroll = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
		// };

		this.init();
		Search();
	}

	init() {
		if (checkMedia('tablet')) {
			this.initHeaderMobileEvents();
		}
		this.initMobileBtn();
		this.headerProfile.addEventListener('click', this.toggleProfileFn);
	}

	initMobileBtn() {
		this.headerMenuBtn = document.getElementById('headerMenuBtn');
		this.headerSearchBtn = document.getElementById('headerSearchBtn');
		this.headerTopBtn = document.getElementById('headerTopBtn');

		this.profileOptionsBtn = this.headerMobile.querySelector(getSelector(stylesHeaderTop.profileOptionsButton));
		this.headerMenuBtn.addEventListener('click', this.openHeaderMobileFn, false);
		this.headerSearchBtn.addEventListener('click', this.openSearchMobileFn, false);
		this.profileOptionsBtn && this.profileOptionsBtn.addEventListener('click', this.openProfileFn, false);
		if (checkMedia('tablet')) {
			this.headerTopBtn.addEventListener('click', this.topBtnFn, false);
		}
	}

	initNavigationEvents() {
		this.navigation = this.headerMobile.querySelector(getSelector(stylesHeaderBottom.headerBottom));
		if (this.navigation) {
			this.navigation.addEventListener('click', this.openNavigationItemFn);
		}
		window.addEventListener('scroll', _throttle(this.scrollFn, 15), false);
	}

	initHeaderMobileEvents() {
		this.initHeaderMenuHelperEvent();
		this.initNavigationEvents();
	}

	closeBlockMobileFn(block) {
		document.documentElement.classList.remove(`${block}-mobile-open`);
	}

	initHeaderMenuHelperEvent() {
		this.headerMenuHelperButton.addEventListener('click', this.clickHeaderMenuHelperFn);
	}

	headerMenuHelperFn() {
		if (!this.headerMenuHelperActive) {
			this.headerMenuHelper.classList.add('active');

			this.headerMenuHelperActive = true;
		}

		const openLists = this.getOpenList();

		if (openLists.length !== 0) {
			const [lastList] = openLists.reverse();

			this.changeHeaderMenuHelperTitle(this.getNavigationItemTitle(lastList));
		} else {
			this.changeHeaderMenuHelperTitle('');
		}
	}

	getOpenList() {
		return Array.from(this.headerMobile.querySelectorAll(`${getSelector(stylesHeaderBottom.list)}.open`));
	}

	getNavigationItemTitle(list) {
		const parent = list.closest(getSelector(stylesHeaderBottom.item));
		return parent.querySelector(getSelector(stylesHeaderBottom.title)).textContent;
	}

	changeHeaderMenuHelperTitle(title) {
		this.headerMenuHelperTitle.textContent = title;
	}

}


class Header {
	constructor() {
		this.headerProfile = document.getElementById('headerProfile');
		this.headerProfileMobileBtnShow = document.getElementById('_headerProfileMobileBtnShow');
		this.headerProfileMobileBtnHide = document.getElementById('_headerProfileMobileBtnHide');
		this.headerProfileOptions = document.getElementById('headerProfileOptions');
		this.headerTop = document.getElementById('_headerTop');

		const self = this;

		this.bodyClickFn = function (e) {
			const target = e.target,
				block = this.block,
				mobile = this.mobile || false;
			let isBlock;
			isBlock = target === block || block.contains(target);

			if (!isBlock) {
				if (mobile) {
					const scrollY = document.body.style.top;
					document.body.style.position = '';
					document.body.style.top = '';
					window.scrollTo(0, parseInt(scrollY || '0') * -1);
					if (document.getElementById('headerMiddle')
						.classList
						.contains('scroll-important')) {
						document.getElementById('headerMiddle')
							.classList
							.remove('scroll-important');
					}
				}
				this.callback(e);
				// self.closeBlockMobileFn(this.block);
				document.body.removeEventListener('click', this);
			}
		};

		this.toggleProfileFn = function (e) {
			if (checkMedia('md')) return false;

			const isOptions = e.target === self.headerProfileOptions || self.headerProfileOptions.contains(e.target);

			if (!isOptions) {
				e.preventDefault();
				e.stopPropagation();

				if (checkMedia('desktop') && self.headerProfileOptions) {
					if (self.headerProfile.classList.contains('show-options')) {
						self.headerProfile.classList.remove('show-options');
					} else {
						self.headerProfile.classList.add('show-options');
						document.body.addEventListener('click', {
							handleEvent: self.bodyClickFn,
							block: self.headerProfile,
							mobile: false,
							callback: self.toggleProfileFn
						}, false);
					}
				}
			}
		};

		this.toggleProfileMobile = function (e, toggle) {
			if (toggle) {
				scrollFixed(true);
				document.documentElement.classList.add('document-block');
				self.headerTop.classList.add('open');
			} else {
				scrollFixed(false);
				document.documentElement.classList.remove('document-block');
				self.headerTop.classList.remove('open');
			}

		}

		this.initEvents();
	}


	initEvents() {
		if (checkMedia('md')) {

			this.headerProfileMobileBtnShow && this.headerProfileMobileBtnShow.addEventListener('click', e => this.toggleProfileMobile(e, true));
			this.headerProfileMobileBtnHide && this.headerProfileMobileBtnHide.addEventListener('click', e => this.toggleProfileMobile(e, false));
		}
		this.headerProfile.addEventListener('click', this.toggleProfileFn);
	}
}

export {
	HeaderOld
}

export default Header;
