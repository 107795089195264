/* eslint-disable */
export default function TopicPostCollapser() {
	$('.TopicPostProfileDetails-moreCollapse').on('click keyup', (event) => {
		let collapser = $(event.target);
		let hiddenContent = collapser.siblings('.TopicPostProfileDetails-more');
		if (collapser.hasClass('TopicPostProfileDetails-moreCollapse--show')) {
			collapser.text('Подробнее');
			hiddenContent.hide();
		} else {
			collapser.text('Скрыть');
			let medals = hiddenContent.find('.TopicPostProfile-medalWrap').eq(1);
			/*
            *  Трюк: в HTML теги img генерируются внутри комментария, поэтому браузер не загружает эти картинки
            *  однако при раскрытии спойлера они нам нужны, поэтому раскомментируем их, после чего браузер
            *  вставляет их в DOM и загружает
            */
			if (medals.find('img').length === 0) {
				medals.contents()
					.filter(function () {
						return this.nodeType === 8;
					})
					.replaceWith(function () {
						return this.data;
					});
			}
			hiddenContent.show();
		}
		collapser.toggleClass('TopicPostProfileDetails-moreCollapse--show');
	});
}
