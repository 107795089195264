/* eslint camelcase: 'off' */
const Noty = require('noty');

function noty_s(a) {
	return new Noty({
		text: a,
		layout: 'topRight',
		type: 'success',
		timeout: 5000,
	}).show();
}

function noty_s_center(a) {
	return new Noty({
		text: a,
		layout: 'center',
		theme: 'lena',
		type: 'success',
		timeout: 2500,
	}).show();
}

function noty_s_center_error(a) {
	return new Noty({
		text: a,
		layout: 'center',
		theme: 'lena',
		type: 'error',
		timeout: 2500,
	}).show();
}


function noty_e(a) {
	return new Noty({
		text: a,
		layout: 'topRight',
		type: 'error',
		timeout: 5000,
	}).show();
}

function noty_a(a) {
	return new Noty({
		text: a,
		layout: 'topRight',
		type: 'alert',
		timeout: 5000,
	}).show();
}

function noty_w(a) {
	return new Noty({
		text: a,
		layout: 'topRight',
		type: 'warning',
		timeout: 5000,
	}).show();
}

export {
	noty_s,
	noty_s_center,
	noty_s_center_error,
	noty_e,
	noty_a,
	noty_w,
	Noty,
};
