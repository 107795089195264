/* eslint-disable */
export default function CommonForum() {
	function popup(url, width, height, name) {
		if (!name) {
			name = '_popup';
		}

		window.open(url.replace(/&amp;/g, '&'), name, `height=${height},resizable=yes,scrollbars=yes,width=${width}`);
		return false;
	}
	window.popup = popup;
	function jumpto() {
		const page = prompt('{LA_JUMP_PAGE}:', '{ON_PAGE}');
		const per_page = '{PER_PAGE}';
		const base_url = '{A_BASE_URL}';
		if (page !== null && !isNaN(page) && page == Math.floor(page) && page > 0) {
			if (base_url.indexOf('?') == -1) {
				document.location.href = `${base_url}?start=${(page - 1) * per_page}`;
			} else {
				document.location.href = `${base_url.replace(/&amp;/g, '&')}&start=${(page - 1) * per_page}`;
			}
		}
	}

	/**
     * Find a member
     */
	function find_username(url) {
		popup(url, 760, 570, '_usersearch');
		return false;
	}
	window.find_username = find_username;
	/**
     * Mark/unmark checklist
     * id = ID of parent container, name = name prefix, state = state [true/false]
     */
	function marklist(id, name, state) {
		const parent = document.getElementById(id);
		if (!parent) {
			return;
		}
		const rb = parent.getElementsByTagName('input');

		for (let r = 0; r < rb.length; r++) {
			if (rb[r].name.substr(0, name.length) == name) {
				rb[r].checked = state;
			}
		}
	}
	window.marklist = marklist;

	function checkUncheckAll(id, name) {
		let checker = document.querySelector('.js-check-all');
		if (checker) {
			if (checker.checked !== undefined) {
				marklist(id, name, checker.checked);
			}
		}
	}
	window.checkUncheckAll = checkUncheckAll;
	function countAdClick(id) {
		loadXMLDoc(`{ADS_CLICK_FILE}?a=${id}`);
	}


	function countAdView(id) {
		loadXMLDoc(`{ADS_VIEW_FILE}?a=${id}`);
	}

	function loadXMLDoc(url) {
		req = false;
		if (window.XMLHttpRequest) {
			try {
				req = new XMLHttpRequest();
			} catch (e) {
				req = false;
			}
		} else if (window.ActiveXObject) {
			try {
				req = new ActiveXObject('Msxml2.XMLHTTP');
			} catch (e) {
				try {
					req = new ActiveXObject('Microsoft.XMLHTTP');
				} catch (e) {
					req = false;
				}
			}
		}
		if (req) {
			req.open('GET', url, true);
			req.send(null);
		}
	}

	/* Forum fn */
	/**
     * Resize viewable area for attached image or topic review panel (possibly others to come)
     * e = element
     */
	function viewableArea(e, itself) {
		if (!e) return;
		if (!itself) {
			e = e.parentNode;
		}

		if (!e.vaHeight) {
			// Store viewable area height before changing style to auto
			e.vaHeight = e.offsetHeight;
			e.vaMaxHeight = e.style.maxHeight;
			e.style.height = 'auto';
			e.style.maxHeight = 'none';
			e.style.overflow = 'visible';
		} else {
			// Restore viewable area height to the default
			e.style.height = `${e.vaHeight}px`;
			e.style.overflow = 'auto';
			e.style.maxHeight = e.vaMaxHeight;
			e.vaHeight = false;
		}
	}

	/**
     * Set display of page element
     * s[-1,0,1] = hide,toggle display,show
     */
	function dE(n, s) {
		const e = document.getElementById(n);

		if (!s) {
			s = (e.style.display == '' || e.style.display == 'block') ? -1 : 1;
		}
		e.style.display = (s == 1) ? 'block' : 'none';
	}

	/**
     * Alternate display of subPanels
     */
	function subPanels(p) {
		let i,
			e,
			t;

		if (typeof (p) === 'string') {
			show_panel = p;
		}

		for (i = 0; i < panels.length; i++) {
			e = document.getElementById(panels[i]);
			t = document.getElementById(`${panels[i]}-tab`);

			if (e) {
				if (panels[i] == show_panel) {
					e.style.display = 'block';
					if (t) {
						t.className = 'activetab';
					}
				} else {
					e.style.display = 'none';
					if (t) {
						t.className = '';
					}
				}
			}
		}
	}


	/**
     * Show/hide groups of blocks
     * c = CSS style name
     * e = checkbox element
     * t = toggle dispay state (used to show 'grip-show' image in the profile block when hiding the profiles)
     */
	function displayBlocks(c, e, t) {
		let s = (e.checked == true) ? 1 : -1;

		if (t) {
			s *= -1;
		}

		const divs = document.getElementsByTagName('DIV');

		for (let d = 0; d < divs.length; d++) {
			if (divs[d].className.indexOf(c) == 0) {
				divs[d].style.display = (s == 1) ? 'none' : 'block';
			}
		}
	}

	function selectCode(a) {
		// Get ID of code block
		const e = a.parentNode.parentNode.getElementsByTagName('CODE')[0];

		// Not IE
		if (window.getSelection) {
			var s = window.getSelection();
			// Safari
			if (s.setBaseAndExtent) {
				s.setBaseAndExtent(e, 0, e, e.innerText.length - 1);
			}
			// Firefox and Opera
			else {
				// workaround for bug # 42885
				if (window.opera && e.innerHTML.substring(e.innerHTML.length - 4) == '<BR>') {
					e.innerHTML += '&nbsp;';
				}

				var r = document.createRange();
				r.selectNodeContents(e);
				s.removeAllRanges();
				s.addRange(r);
			}
		}
		// Some older browsers
		else if (document.getSelection) {
			var s = document.getSelection();
			var r = document.createRange();
			r.selectNodeContents(e);
			s.removeAllRanges();
			s.addRange(r);
		}
		// IE
		else if (document.selection) {
			var r = document.body.createTextRange();
			r.moveToElementText(e);
			r.select();
		}
	}


	/**
     * Check if the nodeName of elem is name
     * @author jQuery
     */
	function is_node_name(elem, name) {
		return elem.nodeName && elem.nodeName.toUpperCase() == name.toUpperCase();
	}

	/**
     * Check if elem is in array, return position
     * @author jQuery
     */
	function is_in_array(elem, array) {
		for (let i = 0, length = array.length; i < length; i++)
		// === is correct (IE)
		{
			if (array[i] === elem) { return i; }
		}

		return -1;
	}

	/**
     * Find Element, type and class in tree
     * Not used, but may come in handy for those not using JQuery
     * @author jQuery.find, Meik Sievertsen
     */
	function find_in_tree(node, tag, type, class_name) {
		let result,
			element,
			i = 0,
			length = node.childNodes.length;

		for (element = node.childNodes[0]; i < length; element = node.childNodes[++i]) {
			if (!element || element.nodeType != 1) continue;

			if ((!tag || is_node_name(element, tag)) && (!type || element.type == type) && (!class_name || is_in_array(class_name, (element.className || element).toString().split(/\s+/)) > -1)) {
				return element;
			}

			if (element.childNodes.length) { result = find_in_tree(element, tag, type, class_name); }

			if (result) return result;
		}
	}

	let in_autocomplete = false;
	let last_key_entered = '';

	/**
     * Check event key
     */
	function phpbb_check_key(event) {
		// Keycode is array down or up?
		if (event.keyCode && (event.keyCode == 40 || event.keyCode == 38)) { in_autocomplete = true; }

		// Make sure we are not within an "autocompletion" field
		if (in_autocomplete) {
			// If return pressed and key changed we reset the autocompletion
			if (!last_key_entered || last_key_entered == event.which) {
				in_autocompletion = false;
				return true;
			}
		}

		// Keycode is not return, then return. ;)
		if (event.which != 13) {
			last_key_entered = event.which;
			return true;
		}

		return false;
	}
	function do_multi_page_jump(e){
		const inpt = e.parentNode.querySelector('.js-gotopage-inpt');
		const url = new URL(window.location.href);
		if (inpt.value) {
			url.searchParams.set('start', (inpt.value-1) * inpt.dataset.perpage);
			window.location = url.href;
		} else {
			noty_e('Введите номер страницы!');
		}


	}
	window.do_multi_page_jump = do_multi_page_jump;

	document.querySelectorAll('.js-collapse').forEach(item =>{
		const hiddenClass = 'visuallyhidden';
		item.addEventListener('click', (e) => {
			const content = e.currentTarget.querySelector('.js-collapse-content');
			if (content.classList.contains(hiddenClass)) {
				content.classList.remove(hiddenClass);
			}
		});
	});

	/**
     * Usually used for onkeypress event, to submit a form on enter
     */
	function submit_default_button(event, selector, class_name) {
		// Add which for key events
		if (!event.which && ((event.charCode || event.charCode === 0) ? event.charCode : event.keyCode)) { event.which = event.charCode || event.keyCode; }

		if (phpbb_check_key(event)) { return true; }

		let current = selector.parentNode;

		// Search parent form element
		while (current && (!current.nodeName || current.nodeType != 1 || !is_node_name(current, 'form')) && current != document) { current = current.parentNode; }

		// Find the input submit button with the class name
		// current = find_in_tree(current, 'input', 'submit', class_name);
		const input_tags = current.getElementsByTagName('input');
		current = false;

		for (let i = 0, element = input_tags[0]; i < input_tags.length; element = input_tags[++i]) {
			if (element.type == 'submit' && is_in_array(class_name, (element.className || element).toString().split(/\s+/)) > -1) { current = element; }
		}

		if (!current) { return true; }

		// Submit form
		current.focus();
		current.click();
		return false;
	}

	/**
     * Apply onkeypress event for forcing default submit button on ENTER key press
     * The jQuery snippet used is based on http://greatwebguy.com/programming/dom/default-html-button-submit-on-enter-with-jquery/
     * The non-jQuery code is a mimick of the jQuery code ;)
     */
	function apply_onkeypress_event() {
		// jQuery code in case jQuery is used
		if (jquery_present) {
			jQuery('form input[type=text], form input[type=password]').live('keypress', function (e) {
				const default_button = jQuery(this).parents('form').find('input[type=submit].default-submit-action');

				if (!default_button || default_button.length <= 0) { return true; }

				if (phpbb_check_key(e)) { return true; }

				if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
					default_button.click();
					return false;
				}

				return true;
			});

			return;
		}

		const input_tags = document.getElementsByTagName('input');

		for (let i = 0, element = input_tags[0]; i < input_tags.length; element = input_tags[++i]) {
			if (element.type == 'text' || element.type == 'password') {
				// onkeydown is possible too
				element.onkeypress = function (evt) {
					submit_default_button((evt || window.event), this, 'default-submit-action');
				};
			}
		}
	}

	/**
     * Detect JQuery existance. We currently do not deliver it, but some styles do, so why not benefit from it. ;)
     */
	var jquery_present = typeof jQuery === 'function';
}
