import './assets/modal.module.scss'
import styles from '@StylesJson/common.module.json';
import stylesModal from '@StylesJson/modal.module.json'
import {getSelector} from '@Js/helpers';

import checkMedia from '@Js/scripts/Media';

class Modal {
	constructor(instance) {
		this.modal = instance;
		this.modalWrapper = this.modal.querySelector(getSelector(stylesModal.modalWrapper)) || this.modal.querySelector('.mobile-modal-wrapper') || this.modal.querySelector(getSelector(stylesModal.mobileModalWrapper));
		this.modalName = this.modal.getAttribute('data-module-modal-name');
		this.isMobile = !!this.modal.classList.contains('mobile-modal');
		const self = this;
		this.bodyCloseFn = function (e) {
			const target = e.target,
				isModal = target === self.modalWrapper || self.modalWrapper.contains(target);

			if (!isModal) {
				self.closeModal();
			}
		};

		this.init();
	}

	init() {
		this.modalOpenClass = 'modal-open';
		this.modalOpenNamedClass = `modal-${this.modalName}-${this.isMobile ? 'mobile-open' : 'open'}`;
		this.getModalTrigger();
		this.initClose();
	}

	getModalTrigger() {
		this.trigger = document.querySelector(`[data-module-modal-trigger="${this.modalName}"]`);
		if (this.trigger) {
			this.trigger.addEventListener('click', (e) => {
				if (this.isMobile) {
					if (checkMedia('tablet')) {
						e.preventDefault();
						e.stopPropagation();
						this.openModal();
					}
				} else {
					e.preventDefault();
					e.stopPropagation();
					this.openModal();
				}
			})
		}
	}

	initClose() {
		const closes = this.modal.querySelectorAll('[data-module-modal-close]');
		if (closes.length !== 0) {
			closes.forEach(close => {
				close.addEventListener('click', () => {
					this.closeModal();
				})
			});
		}
	}

	openModal() {
		const scrollY = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
		if (document.getElementById('headerMiddle').classList.contains('scroll')) {
			document.getElementById('headerMiddle').classList.add('scroll-important');
		}
		document.documentElement.classList.add('document-block');
		document.body.style.position = 'fixed';
		document.body.style.top = `-${scrollY}px`;
		document.documentElement.classList.add(this.modalOpenClass);
		document.documentElement.classList.add(this.modalOpenNamedClass);
		this.modal.classList.add('open');
		this.modal.addEventListener('click', this.bodyCloseFn);
		window.currentOpenModal = this;
	}


	closeModal() {
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
		if (document.getElementById('headerMiddle').classList.contains('scroll-important')) {
			document.getElementById('headerMiddle').classList.remove('scroll-important');
		}
		document.documentElement.classList.remove('document-block');
		document.documentElement.classList.remove(this.modalOpenClass);
		document.documentElement.classList.remove(this.modalOpenNamedClass);
		this.modal.classList.remove('open');
		this.modal.removeEventListener('click', this.bodyCloseFn);
		window.currentOpenModal = undefined;
	}

}

export default Modal;
