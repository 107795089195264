export default function JumpBox() {
	document.querySelectorAll('.Jumpbox-select')
		.forEach((domElement) => {
			domElement.addEventListener('change', (e) => {
				if (e.currentTarget.value !== '-1') {
					e.currentTarget.parentElement.submit();
				}
			});
		});
}
