import { getSelector } from '@/js/helpers';
import stylesHeaderMiddle from '@StylesJson/header-middle.module';
import checkMedia from '@Js/scripts/Media';

// require('jquery-ui/ui/widgets/autocomplete');

require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/autocomplete.css');

let selected = false;

export default function Search() {
	let searchVal;

	const $autocomplete = $('#searchBox')
		.submit(() => { // eslint-disable-line consistent-return
			searchVal = $('#searchBoxInput')
				.val()
				.trim();
			if (searchVal === ''
				|| searchVal === 'Хочу купить...'
				|| searchVal.length < 3) {
				noty_w('Введите корректный запрос поиска');
				return false;
			}
			$.getJSON('/forum/spsearch.php', {
				term: searchVal,
				save: 1,
			});
		});
	const searchCache = {};
	let searchFull = 0;
	// $.ui.autocomplete.prototype._resizeMenu = function () {
	// 	this.menu.element.outerWidth(this.element.offsetWidth);
	// };

	const helperTitle = $('#siteSearchHelperTitle');

	$('#searchBoxInput')
		.autocomplete({
			appendTo: '#siteSearchAutocomplete',
			classes: {
				'autocomplete': stylesHeaderMiddle.searchAutocompleteList
			},
			position: {
				of: '#siteSearchAutocomplete',
				collision: 'none',
				my: 'left top',
				at: 'left top'
			},
			minLength: 3,
			source(request, response) {
				const term = request.term; // eslint-disable-line prefer-destructuring
				if (term in searchCache) {
					response(searchCache[term]);
					return;
				}
				request.full = searchFull;
				request.pc = $('#searchBoxInput')
					.val();
				$.getJSON('/forum/spsearch.php', request, (data) => {
					searchCache[term] = data;
					response(data);

					this.menu.element.outerWidth($(getSelector(stylesHeaderMiddle.searchForm))[0].offsetWidth);
				});
			},
			// select: function(data, value ) {
			// 	console.log(data);
			// 	console.log(value);
			// },
			select(data, value) {
				// selected = true;
				// if (value.item.url) {
				// 	$('#siteSearchBoxProductcat')
				// 		.val(value.item.url);
				// 	$('#searchBox')
				// 		.submit();
				// 	return false;
				// }
				// if (value.item.full) {
				// 	searchFull = 1;
				// 	const inp = $('#searchBoxInput');
				// 	delete searchCache[inp.val()];
				// 	inp.autocomplete('search');
				// 	return false;
				// }
				// $('#searchBoxInput')
				// 	.val(value.item.label);
				// $('#searchBox')
				// 	.submit();
				//
				// return false;
				// window.location = value.item.url;
			},
			// focus(data, value) { // eslint-disable-line consistent-return
			// 	return false;
			// 	// if (value.item.url) {
			// 	// 	$('#siteSearchBoxProductcat')
			// 	// 		.val(value.item.url);
			// 	// 	return false;
			// 	// }
			// 	// if (value.item.full) {
			// 	// 	return false;
			// 	// }
			// 	// $('#siteSearchBoxProductcat')
			// 	// 	.val(0);
			// },

			open() {
				$('#siteSearch')
					.addClass(stylesHeaderMiddle.searchOpen);
				if (helperTitle.css('display') !== 'none' && helperTitle.text() !== 'Результаты') {
					helperTitle.fadeTo(200, 0, function () {
						$(this)
							.text('Результаты')
							.fadeTo(100, 1);
					});
				}
			},
			close() {
					$('#siteSearch')
						.removeClass(stylesHeaderMiddle.searchOpen);
				if (helperTitle.css('display') !== 'none' && helperTitle.text() !== 'Популярные запросы') {
					helperTitle.fadeTo(200, 0, function () {
						$(this)
							.text('')
							.fadeTo(100, 1);
					});
				}
			}
		})
		.data('autocomplete')._renderItem = function (ul, item) {
		searchVal = $('#searchBoxInput')
			.val()
			.trim();
		return $('<li>')
			// .data('item.autocomplete', item)
			.addClass('ui-menu-item')
			.addClass(stylesHeaderMiddle.searchAutocompleteListItem)
			.attr('data-url', item.url)
			.attr('data-label', item.url ? searchVal : item.label)
			.append(`<span class="${stylesHeaderMiddle.searchAutocompleteListItemText}">${item.label.replace(searchVal, '<span class="' + stylesHeaderMiddle.searchAutocompleteListItemTextHightlight + '">' + searchVal + '</span>')}</span><span class="${stylesHeaderMiddle.searchAutocompleteListItemCounter}">${(item.count || '')} </span>`)
			.appendTo(ul);
	};

	$(document).on('click',`.${stylesHeaderMiddle.searchAutocompleteListItem}`,function() {
		const url = $(this).attr('data-url'),
			label = $(this).attr('data-label');

		$('#siteSearchBoxProductcat')
			.val(url || 0);
		$('#searchBoxInput').val(label);
		$('#searchBox')
			.submit();

	});

		// $('#search
	//BoxInput').on( "autocompleteclose", function( event, ui ) {
	// 		if (media.tablet) {
	// 			$('#searchBoxInput').
	// 		}
	// } );
	// (function(){
	// 	const originalCloseMethod = $autocomplete.data("ui-autocomplete-item").close;
	// 	$autocomplete.data("ui-autocomplete-item").close = function(event) {
	// 		if (!selected){
	// 			//close requested by someone else, let it pass
	// 			originalCloseMethod.apply( this, arguments );
	// 		}
	// 		selected = false;
	// 	};
	// })();

}
