import axios from 'axios';
import qs from 'qs';

const newsBlock = document.querySelector('#newsBlock');

if (newsBlock) {
  const newsBlockClose = document.querySelector('#newsBlockClose');

  newsBlockClose.addEventListener('click', () => {
    const queries = [];
    const news = newsBlock.querySelectorAll('[data-news-id]');

    if (news) {
      news.forEach((newsItem) => {
        queries.push(axios({
          method: 'POST',
          url: '/forum/events.php',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify({
            mode: 'done_news',
            news_id: parseInt(newsItem.dataset.newsId, 10),
          }),
        }));
      });
    }

    Promise.all(queries).then(() => {
      $(newsBlock).slideUp(250);
    });
  });
}
